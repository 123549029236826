const bookings = [
  {
    clientName: 'Digital Golem',
    startDate: '2019-05-14',
    endDate: '2019-06-14',
  },
  {
    clientName: 'nextmoov',
    startDate: '2019-07-20',
    endDate: '2019-08-18',
  },
];

const unavailableSlots = [
  {
    startDate: '2019-05-01',
    endDate: '2019-05-12',
  },
  {
    startDate: '2019-08-20',
    endDate: '2019-09-12',
  },
];

const offers = [
  {
    clientName: 'Digital Golem',
    offerId: '',
  },
  {
    clientName: 'nextmoov',
    offerId: '',
  },
];

export const slots = [
  { title: 'From ' },
  { title: '2019-05-24', highlighted: true, date: true },
  { title: ' to ' },
  { title: '2019-05-25', highlighted: true, date: true },
];

export const dashboard = {
  bookings,
  unavailableSlots,
  offers,
};
