import React from 'react';
import DashBoard from './component';
import { dashboard } from '../../mocks/dashboard';

function DashBoardScreenContainer() {
  return (
    <DashBoard
      bookings={dashboard.bookings}
      unavailableSlots={dashboard.unavailableSlots}
      applications={dashboard.offers}
    />
  );
}

export default DashBoardScreenContainer;
