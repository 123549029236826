// @flow
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AutosizeInput from 'react-input-autosize';
import s from '../../screens/DashboardScreen/style.module.scss';

type SlottedSentenceProps = {
  slots: Array,
}

function SlottedSentence(props: SlottedSentenceProps) {
  const {
    slots,
  } = props;

  return (
    <p className={s.dashboardWrapper__text}>
      {slots.map((slot, index) => {
        if (slot.highlighted) {
          if (slot.link) {
            return (
              <Link
                key={`${slot.title + index}`}
                to={slot.link}
                className={s.dashboardWrapper__text__highlight}
              >
                {slot.title}
              </Link>
            );
          }
          if (slot.input) {
            return (
              <AutosizeInput
                key={`${slot.title + index}`}
                injectStyles={false}
                className={s.dashboardWrapper__textInput}
                value={slot.title}
                onChange={slot.onChange}
                placeholder={slot.placeholder}
                // NOTE: in this case it improves UX
                // eslint(jsx-a11y/no-autofocus)
                // eslint-disable-next-line
                autoFocus
              />
            );
          }
          return (
            <span key={`${slot.title + index}`} className={s.dashboardWrapper__text__highlight}>
              {slot.date ? moment(slot.title).format('MMMM DD') : slot.title}
            </span>
          );
        }
        return `${slot.title}`;
      })}
    </p>
  );
}

export default SlottedSentence;
