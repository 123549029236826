import React, { useState } from 'react';
import s from './style.module.scss';
import Header from '../../containers/Header';
import Button from '../../components/Button';
import Title from '../../components/Title';
import SlottedSentence from '../../components/SlottedSentence';
import { keepAlphabet } from '../../utils/regex';
import type { Dashboard } from '../../types/dashboard';

function DashboardScreen(props: Dashboard) {
  const {
    bookings,
    unavailableSlots,
    applications,
  } = props;

  const [showBookings, setShowBookings] = useState(false);
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [showOffers, setShowOffers] = useState(false);

  const bookingsList = bookings.map((booking, index) => {
    if (index > 0) {
      return (
        <SlottedSentence
          key={booking}
          slots={[
            { title: `${booking.clientName},`, highlighted: true, link: `/profile/${keepAlphabet(booking.clientName)}` },
            { title: ' from ' },
            { title: booking.startDate, highlighted: true, date: true },
            { title: ' until ' },
            { title: booking.endDate, highlighted: true, date: true },
          ]}
        />
      );
    }
    return null;
  });

  const unavailableSlotsList = unavailableSlots.map((slot, index) => {
    if (index > 0) {
      return (
        <SlottedSentence
          key={slot}
          slots={[
            { title: slot.startDate, highlighted: true, date: true },
            { title: ' to ' },
            { title: slot.endDate, highlighted: true, date: true },
          ]}
        />
      );
    }
    return null;
  });

  const applicationsList = applications.map((application) => {
    console.log(application);
    return (
      <SlottedSentence
        key={application.clientName}
        slots={[
          { title: `- ${application.clientName}`, highlighted: true, link: `/offer/${keepAlphabet(application.clientName)}` },
        ]}
      />
    );
  });

  return (
    <>
      <Header screenTitle="DashBoard" isHome isDashboard />

      <div className={s.dashboardWrapper}>
        <Title
          level={5}
          content={!showBookings ? 'Your next booking' : 'Your next bookings'}
        />
        <SlottedSentence
          slots={[
            { title: `${bookings[0].clientName},`, highlighted: true, link: `/profile/${keepAlphabet(bookings[0].clientName)}` },
            { title: ' from ' },
            { title: bookings[0].startDate, highlighted: true, date: true },
            { title: ' until ' },
            { title: bookings[0].endDate, highlighted: true, date: true },
          ]}
        />
        {showBookings && bookingsList}
        {bookings.length > 1 && (
          <Button
            content={!showBookings ? 'All my bookings' : 'Show less bookings'}
            onClick={() => { setShowBookings(!showBookings); }}
            secondary
          />
        )}
      </div>

      <div className={s.dashboardWrapper}>
        <Title
          level={5}
          content={!showUnavailable ? 'Next unavailable slot' : 'All my unavailabilities'}
        />
        <SlottedSentence
          slots={[
            { title: unavailableSlots[0].startDate, highlighted: true, date: true },
            { title: ' to ' },
            { title: unavailableSlots[0].endDate, highlighted: true, date: true },
          ]}
        />
        {showUnavailable && unavailableSlotsList}
        {unavailableSlots.length > 1 && (
          <Button
            content={!showUnavailable ? 'Check my unavailabilities' : 'Show less unavailabilities'}
            onClick={() => { setShowUnavailable(!showUnavailable); }}
            secondary
          />
        )}
      </div>

      <div className={s.dashboardWrapper}>
        <Title
          level={5}
          content="Your current applications"
        />
        <SlottedSentence
          slots={[
            { title: 'You have applied to ' },
            { title: `${applications.length} ${applications.length > 1 ? 'offers' : 'offer'}`, highlighted: true },
          ]}
        />
        {showOffers && applicationsList}
        <Button
          content={!showOffers ? 'All my applications' : 'Hide applications'}
          onClick={() => { setShowOffers(!showOffers); }}
          secondary
        />
      </div>

    </>
  );
}

export default DashboardScreen;
